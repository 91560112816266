import * as React from "react"
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Hero } from '../components/Hero';
import { Meta } from '../components/Meta';

// markup
const IndexPage = () => {
  return (
    <main className="font-body text-black overflow-hidden">
      <Meta title="Home page" bodyClasses="" />
      <Header />
      <section className="hero-section bg-hero-background bg-cover bg-left-bottom bg-no-repeat" id="hero">
        <div className="mx-auto">
          <Hero />
        </div>
      </section>
      <section className="who-are-section container mx-auto p-4 md:p-0" id="who-are">
        <div className="section-content flex-col md:flex-row flex pt-8 md:pt-20">
          <div className="md:w-5/12">
            <div className="w-full mb-4 md:mb-0 md:mx-4 md:pt-16 ">
              <h4 className="text-dirtBrown text-lg font-Bold mb-2">Who We Are</h4>
              <p className="mb-20"><span className="bg-tealish1 font-Medium">A Multi-product startup</span> with continuous game-changing innovation to empower people.</p>
            </div>
            <div className="w-full mb-4 md:mb-0 md:mx-4">
              <h4 className="text-dirtBrown text-lg font-Bold mb-2">Our Mission</h4>
              <p className="mb-2">We challenge to explore new demand - new market in Vietnam through our products development to <span className="bg-tealish1 font-Medium">make lives better.</span></p>
              <p className="mb-6">We do not hesitate to use the latest technologies to deliver value to users.</p>
              <a className="text-tealish font-Bold flex items-center" href="/products">MORE ABOUT OUR PRODUCTS
              <img className="object-contain ml-2" src="../../ic_arrow-right.svg" />
              </a>
            </div>
          </div>
          <div className="md:w-7/12">
            <div className="features features-3 w-full mb-4 md:mb-0 md:mx-4">
              <img className="object-contain" src="../../member-of-company.png"  />
            </div>
          </div>
        </div>
      </section>
      <section className="who-are-section relative bg-gray-light" id="who-are">
        <div className="absolute text-8xl md:text-10xl inset-x-0 text-gray-blue/5 tracking-widest text-center pt-7 font-Bold">OUR CULTURE</div>
        <div className="container mx-auto">
          <div className="section-heading pt-20 md:pt-28 md:mb-20 mb-4 px-4 md:px-0">
            <h1 className="flex justify-center text-center text-md text-gray-blue mb-4 font-Medium">What’s the best thing about working at MMJ (Vietnam)?</h1>
            <p className="w-full md:w-1/2 mx-auto text-center text-gray-blue">
              It’s our Culture and People. We have a comfortable working environment where our people can be their best selves. We have a purpose to go to work each day, enjoy our work and collaborate with each other to build awesome products that bring value to lives.
            </p>
          </div>
          <div className="section-content flex flex-col md:flex-row px-4 md:px-0">
            <div className="w-full md:w-6/12">
              <div className="who-are1 w-1/3 md:w-1/2 relative mb-4 md:mb-0 md:mx-4 ">
                <img className="object-contain" src="../../who-are1.png"  />
                <div className="who-are2 w-full absolute top-2 left-full md:top-3/4 md:left-1/3 mb-4 md:mb-0 md:mx-4">
                  <img className="object-contain" src="../../who-are2.png"  />
                  <div className="who-are2 w-full absolute top-4 left-full md:top-3/4 md:left-1/3 mb-4 md:mb-0 md:mx-4">
                    <img className="object-contain" src="../../who-are3.png"  />
                </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 pt-12 md:pt-0">
              <div className="w-full mb-4 md:mb-0 md:mx-4 flex items-start">
                <img className="object-contain w-12 h-12 mr-6" src="../../ic-just-do-it.png"  />
                <div>
                  <h4 className="text-md text-gray-blue mb-2 font-Bold">Just do it</h4>
                  <p className="md:mb-8 mb-4">Working in Startup is full of Uncertainty. Too much discussion will not work. We discuss a bit, then just do it, then discuss again with the actual data. Repeating the process until we get the result. We have an open meeting area where discussion and conclusion are made. Normally NO FORMAL MEETING.</p>
                </div> 
              </div>
              <div className="w-full mb-4 md:mb-0 md:mx-4 flex items-start">
                <img className="object-contain w-12 h-12 mr-6" src="../../ic-be-unprofessional.png"  />
                <div>
                  <h4 className="text-md text-gray-blue mb-2 font-Bold">Be Unprofessional</h4>
                  <p className="md:mb-8 mb-4">To make results in the uncertain world, we need to make SMART MISTAKES. Being “professional” or “perfectionist” and afraid of making mistakes are 100% against this mindset. Make a lot of smart mistakes and learn!</p>
                </div>
              </div>
              <div className="w-full mb-4 md:mb-0 md:mx-4 flex items-start">
                <img className="object-contain w-12 h-12 mr-6" src="../../ic-we-are-flat.png"  />
                <div>
                  <h4 className="text-md text-gray-blue mb-2 font-Bold">We’re Flat</h4>
                  <p className="md:mb-8 mb-4">We listen to you, no matter who you are, if your opinion makes sense. We do not listen to you, no matter who you are, if your opinion is not towards users.</p>
                </div>
              </div>
              <div className="w-full mb-4 md:mb-0 md:mx-4 flex items-start">
                <img className="object-contain w-12 h-12 mr-6" src="../../ic-no-politics.png"  />
                <div>
                  <h4 className="text-md text-gray-blue mb-2 font-Bold">No Politics</h4>
                  <p className="md:mb-8 mb-4">We purely work for users, not for anyone else. Any action that benefits only yourself will not be considered as a valid behavior here.</p>
                </div>
              </div>
              <div className="w-full mb-4 md:mb-0 md:mx-4 flex items-start">
                <img className="object-contain w-12 h-12 mr-6" src="../../ic-wolf.png"  />
                <div>
                  <h4 className="text-md text-gray-blue mb-2 font-Bold">Be a Wolf, not a Sheep</h4>
                  <p className="md:mb-8 mb-4">We need a Wolf who can decide by yourself what to do and how to do it, instead of a sheep who is passive and is told what to do.</p>
                </div>
              </div>
              <div className="w-full mb-4 md:mb-0 md:mx-4 flex items-start">
                <img className="object-contain w-12 h-12 mr-6" src="../../ic-psychological-safety.png"  />
                <div>
                  <h4 className="text-md text-gray-blue mb-2 font-Bold">Psychological Safety</h4>
                  <p className="md:mb-8 mb-4">We never laugh at a SILLY IDEA because silly ideas are seeds of a great idea. On the other hand, we don’t welcome you being silent. Asking questions, sharing your thoughts, and speaking up are required.</p>
                </div>
              </div>
              <div className="w-full mb-4 md:mb-0 md:mx-4 flex items-start">
                <img className="object-contain w-12 h-12 mr-6" src="../../ic-work-smart.png"  />
                <div>
                  <h4 className="text-md text-gray-blue mb-2 font-Bold">Work smart</h4>
                  <p className="md:mb-8 mb-4">We love to see 1 hour of quick work that improves 10% of service performance rather than 8 hours of diligent work that improves 0.1% of performance.</p>
                </div>
              </div>
              <div className="md:mb-28 mb-8">
                <a className="text-sm text-gray-blue font-Bold" href="/culture">MORE ABOUT OUR CULTURE</a>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-full">
          <div className="w-full mb-4 md:mb-0">
            <img className="object-contain" src="../../team-building.png"  />
          </div>
        </div>
      </section>
      <Footer />
    </main>
  )
}

export default IndexPage
