import React, { Component } from "react";
import { navigate } from "gatsby";
import Slider from "react-slick";

export function RightHeroImage (props) {
  return (
    <div className="md:w-4/12 pl-0 md:pl-3">
      <div className="hero-image-blur">
        <div className="w-3/4 md:w-full mx-auto md:pt-0 pt-12">
          <img className="object-contain" src={props.imageSource} />
        </div>
      </div>
    </div>
  )
}

export function Hero (props) {
  const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      dotsClass: 'slick-dots container mx-auto',
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1
  };

  const slipText = (text: string): React.ReactNode => {
    const letters = text.split('');
    return (letters.map((el, index) => {
      return (<span className="text-split">
        {el === ' ' ? <span className="text-split__inner">&nbsp;</span> : <span className="text-split__inner">{el}</span>}
      </span>);
    }))
  }

  return (
    <Slider {...settings}>
      <div>
        <div className="container mx-auto hero-section--container items-center flex md:flex-row flex-col py-20 md:py-32">
          <div className="md:w-8/12 pr-0 md:pr-3">
            <h1 className="text text-3xl md:text-4xl md:px-0 px-4 font-monoBold">
              <span className="text-pink">{slipText('YourCodes:')}<br /></span>
              <div className="flex flex-wrap">
                <span className="text-blueGrey">{slipText('solve=')}</span>
                <span className="text-tealish">{slipText('RealWorld')}</span>
                <span className="text-blueGrey">{slipText('(')}</span><span className="text-softGreen">{slipText('“problems”')}</span><span className="text-blueGrey text-split__inner">{slipText(')')}</span>
              </div>
            </h1>
            <p className="text-gray-slate text-sm md:text-md mt-4 mb-1.5 px-4 md:px-0 md:mt-8 font-monoItalic"># We provide a psychologically safe environment for you to try every single idea.</p>
            <button onClick={()=>{navigate("/joinOurTeam")}} id="btn_launch-app" className="text-white px-4 md:px-0 text-md font-Medium mt-8 md:ml-0 md:mt-16 btn-primary flex items-center">View job openings<img className="arrow-icon object-contain ml-2 md:ml-4" src="https://cdn-static.ymeet.me/flima/right-arrow-white.svg" /></button>
          </div>
          <RightHeroImage imageSource={"../../img-coding.png"} />
        </div>
      </div>
      <div>
        <div className="container mx-auto hero-section--container items-center flex md:flex-row flex-col py-20 md:py-32">
          <div className="md:w-8/12 pr-0 md:pr-3">
            <h1 className="text-4xl text-white md:px-0 px-4 font-Bold">
              {slipText('A')}
              <span className="text-pink">{slipText(' Multi Products ')}</span>
              <span className="text-tealish">{slipText('Startup ')}</span>
              {slipText('in Vietnam')}
            </h1>
            <p className="text-gray-slate text-sm md:text-md mt-4 mb-1.5 px-4 md:px-0 md:mt-8 font-monoItalic"># We provide a psychologically safe environment for you to try every single idea.</p>
            <button onClick={()=>{navigate("/joinOurTeam")}} id="btn_launch-app" className="text-white px-4 md:px-0 text-md font-Medium mt-8 md:ml-0 md:mt-16 btn-primary flex items-center">View job openings<img className="arrow-icon object-contain ml-2 md:ml-4" src="https://cdn-static.ymeet.me/flima/right-arrow-white.svg" /></button>
          </div>
          <RightHeroImage imageSource={"../../img-coding.png"} />
        </div>
      </div>
      <div>
        <div className="container mx-auto hero-section--container items-center flex md:flex-row flex-col py-20 md:py-32">
          <div className="md:w-8/12 pr-0 md:pr-3">
            <h1 className="text-4xl text-white md:px-0 px-4 font-Bold">
              {slipText('Be')}
              <span className=" text-pink">{slipText(' Un')}</span>
              {slipText('professional')}
            </h1>
            <p className="text-gray-slate text-sm md:text-md mt-4 mb-1.5 px-4 md:px-0 md:mt-8 font-monoItalic"># We provide a psychologically safe environment for you to try every single idea.</p>
            <button onClick={()=>{navigate("/joinOurTeam")}} id="btn_launch-app" className="text-white px-4 md:px-0 text-md font-Medium mx-auto md:ml-0 md:mt-16 btn-primary flex items-center">View job openings<img className="arrow-icon object-contain ml-2 md:ml-4" src="https://cdn-static.ymeet.me/flima/right-arrow-white.svg" /></button>
          </div>
          <RightHeroImage imageSource={"../../img-coding.png"} />
        </div>
      </div>
      <div>
        <div className="container mx-auto hero-section--container items-center flex md:flex-row flex-col py-20 md:py-32">
          <div className="md:w-8/12 pr-0 md:pr-3">
            <h1 className="text-4xl text-white md:px-0 px-4 font-Bold">
              {slipText('We')}
              <span className="text-pink">{slipText(' Love ')}</span>
              <span className="text-tealish">{slipText('Flat ')}</span>
              <span className="text-tealish">{slipText('Team')}</span>
            </h1>
            <p className="text-gray-slate text-sm md:text-md mt-4 mb-1.5 px-4 md:px-0 md:mt-8 font-monoItalic"># We provide a psychologically safe environment for you to try every single idea.</p>
            <button onClick={()=>{navigate("/joinOurTeam")}} id="btn_launch-app" className="text-white px-4 md:px-0 text-md font-Medium mx-auto md:ml-0 md:mt-16 btn-primary flex items-center">View job openings<img className="arrow-icon object-contain ml-2 md:ml-4" src="https://cdn-static.ymeet.me/flima/right-arrow-white.svg" /></button>
          </div>
          <RightHeroImage imageSource={"../../img-coding.png"} />
        </div>
      </div>
    </Slider>
  );
}